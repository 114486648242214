import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useSelector } from 'react-redux';
import {
  ArticleContent,
  ConditionalArticleMainImage,
  ArticleTitle,
  SocialButtons,
  RelatedPosts,
  Slideshow,
  MEDIA_BREAKPOINTS,
  PostDates,
  ContentAndSidebar,
  PostMainContent,
  SectionWithTitle,
  AuthorBio,
  InThisStoryComponent,
  SportradarFactory,
  SPORTRADAR_NAMES,
  TEAM_ID,
  Sponsor,
  LARGE_SCREEN_SIZE,
  BreadCrumbs,
  MMPlayerPlaceHolder,
  KeepScroll,
} from 'mm-ui-components';
import { createBlocksWidth } from '../postPage.util';
import {
  ArticleComponentDataProps,
  MainArticleComponentDataProps,
} from '../utils/articleComponent.utils';
import { DisclaimersAtDynamicPosition } from '../../components/disclaimer/DisclaimersAtDynamicPosition';
import { getUseFormatMinutesHoursAgo } from '../../store/config/config.selectors';
import { HeaderPostSidebarConnected } from '../../components/connectedComponents/PostSidebarConnected';
import { AdditionalStyleSheet } from '../templates.utils';
import { getSubHeader } from '../../store/navigations/navigation/header/header.selectors';

const defaultBlocksWidths = createBlocksWidth(740, 720, 640);

const createStyles = (isFullBleed: boolean) => StyleSheet.create({
    socialContainer: {
      [MEDIA_BREAKPOINTS.large]: {
        margin: isFullBleed ? '30px 20px' : '20px 20px 30px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        margin: '20px 0 30px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        margin: '20px 20px 30px',
      },
    },
    inStoryContainer: {
      [MEDIA_BREAKPOINTS.large]: {
        margin: isFullBleed ? '20px 20px 25px' : '0 20px 25px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        margin: '20px 0 15px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        margin: '30px 20px 15px',
      },
    },
    divider: {
      [MEDIA_BREAKPOINTS.small]: {
        margin: '0 20px',
      },
    },
    authorSocial: {
      marginBottom: '20px',
    },
    authorBio: {
      margin: '0 0 20px 10px',
    },
    bottomPlaceholder: {
      margin: 'auto',
      maxWidth: `calc(${LARGE_SCREEN_SIZE}px - 40px)`,
      [MEDIA_BREAKPOINTS.medium]: {
        width: '75%',
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: '0px 20px',
        width: 'auto',
      },
    },
  });

const datesContainer = StyleSheet.create({
  style: {
    [MEDIA_BREAKPOINTS.large]: {
      marginTop: '30px',
      paddingLeft: '20px',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      maxWidth: '720px',
      padding: '0px 20px',
    },
    [MEDIA_BREAKPOINTS.small]: {
      maxWidth: '640px',
      margin: '20px auto 0',
      padding: '0px 20px',
    },
  },
}) as AdditionalStyleSheet;

const contentAdditionalStyles = () => StyleSheet.create({
    style: {
      [MEDIA_BREAKPOINTS.large]: {
        padding: '0',
      },
    },
  }) as AdditionalStyleSheet;

const sidebarAdditionalStyles = () => StyleSheet.create({
    style: {
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '2fr 340px',
      },
    },
  }) as AdditionalStyleSheet;

const mmPlayerAdditionalStyles = () => StyleSheet.create({
    style: {
      marginTop: '20px',
    },
  }) as AdditionalStyleSheet;

const keepScrollAdditionalStyle = () => StyleSheet.create({
    style: {
      padding: '40px 20px',
    },
  }) as AdditionalStyleSheet;

export const FijiArticleComponent: React.FunctionComponent<
  ArticleComponentDataProps
> = props => {
  const {
    title,
    intro,
    by,
    createdAt,
    breadCrumbs,
    createdAtISO,
    updatedAt,
    updatedAtISO,
    showUpdatedAt,
    shareConfig,
    cover,
    siteName,
    templateName,
    body,
    slideshow,
    commercialTags,
    ownerUsername,
    resourceID,
    sideBar2AdOnSuccess,
    sideBar1AdOnSuccess,
    sideBar1Id,
    sideBar2Id,
    disclaimerText,
    reviewDisclaimerText,
    shouldDisplayDisclaimerAtBottom,
    shouldShowHeroImage = true,
    isMobileViewer,
    sponsor,
    shouldShowAds = true,
  } = props;
  const {
    experiments,
    canonical,
    addMMPlayerPlaceholder = false,
    shouldAddKeepScroll = false,
  } = props as MainArticleComponentDataProps;
  const {
    authors,
    inThisStoryData,
    relatedPosts,
    isFullBleed = false,
  } = props as any;
  const useFormatMinutesHoursAgo = useSelector(getUseFormatMinutesHoursAgo);
  const styles = createStyles(isFullBleed);
  const breadCrumbsMarginFactor = {
    largeMarginFactor: 2,
    mediumMarginFactor: 1,
    smallMarginFactor: 1,
  };

  const buildAuthorBioProps = (author: any) => {
    const { socialLinks, image, name, bio, link: authorLink } = author;
    const twitterData = (socialLinks?.length
        && socialLinks.filter(
          (socialLink: any) => socialLink.alt.toLowerCase() === 'twitter',
        )) || [];
    let socialLink = '';
    let socialNickname = '';

    if (twitterData?.length) {
      socialLink = twitterData[0].href;
      socialNickname = twitterData[0].href.split('.com/').pop();
    }

    return {
      socialNickname,
      socialLink,
      bio,
      image,
      name,
      authorLink,
    };
  };

  const isCoverVideoExperiment = (experiments?.includes('video-placement-desktop|cover') && !isMobileViewer) || (experiments?.includes('video-placement-mobile|cover') && isMobileViewer);

  return (
    <div>
      <ContentAndSidebar additionalStyles={sidebarAdditionalStyles()}>
        <PostMainContent additionalStyles={contentAdditionalStyles()}>
          <header>
            <ArticleTitle
              title={title}
              by={by}
              createdAt={createdAt}
              createdAtISO={createdAtISO}
              updatedAt={updatedAt}
              updatedAtISO={updatedAtISO}
              showUpdatedAt={showUpdatedAt}
              authors={authors}
              intro={intro}
              useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
            />
            {!isCoverVideoExperiment && !isFullBleed && shouldShowHeroImage && (
              <ConditionalArticleMainImage
                {...cover}
                siteName={siteName}
                templateName={templateName}
                defaultBlocksWidths={defaultBlocksWidths}
                paddingOverride={{
                  largePadding: 0,
                  mediumPadding: 0,
                  smallPadding: 0,
                }}
              />
            )}
            {isCoverVideoExperiment && (
              <MMPlayerPlaceHolder
                renderForMobile={isMobileViewer}
                renderForTabletAndDesktop={!isMobileViewer}
                isMobileViewer={isMobileViewer}
                additionalStyle={mmPlayerAdditionalStyles()}
                defaultBlocksWidths={defaultBlocksWidths}
                mmVideoPlaceholderId="mmvid"
              />
            )}
            {inThisStoryData?.inThisStory?.length ? (
              <div className={css(styles.inStoryContainer)}>
                <InThisStoryComponent
                  title={inThisStoryData.title}
                  inThisStory={inThisStoryData.inThisStory}
                />
              </div>
            ) : null}
            { canonical && shareConfig && (
            <div className={css(styles.socialContainer)}>
              <SocialButtons shareConfig={shareConfig} canonical={canonical} />
            </div>
            )}
            <hr className={css(styles.divider)} />
          </header>
          {sponsor ? <Sponsor {...sponsor} /> : null}
          <Slideshow
            {...slideshow}
            experiments={experiments}
            resourceID={resourceID}
            commercialTags={commercialTags}
            ownerUsername={ownerUsername}
            blockWidths={defaultBlocksWidths}
          >
            <DisclaimersAtDynamicPosition
              disclaimerText={disclaimerText}
              reviewDisclaimerText={reviewDisclaimerText}
              defaultBlocksWidths={defaultBlocksWidths}
              shouldDisplayDisclaimerAtBottom={shouldDisplayDisclaimerAtBottom}
            >
              <ArticleContent
                body={body}
                templateName={templateName}
                siteName={siteName}
                defaultBlocksWidths={defaultBlocksWidths}
                blocksPaddingOverride={{
                  smallPadding: 0,
                  mediumPadding: 0,
                  largePadding: 0,
                }}
                totalSlides={slideshow.totalSlides}
                experiments={experiments}
                commercialTags={commercialTags}
                slidesLinks={slideshow.slidesLinks}
                resourceID={resourceID}
                ownerUsername={ownerUsername}
                addMMPlayerPlaceholder={addMMPlayerPlaceholder && !isCoverVideoExperiment}
                imageWidthLarge={620}
                imageWidthMedium={620}
                imageWidthSmall={360}
                mmVideoPlaceholderId="mmvid"
                isMobileViewer={isMobileViewer}
              />
              <SectionWithTitle additionalStyle={datesContainer}>
                <hr />
                <PostDates
                  {...{
                    updatedAt,
                    updatedAtISO,
                    createdAt,
                    createdAtISO,
                    showUpdatedAt,
                    useFormatMinutesHoursAgo,
                  }}
                />
                {authors?.length && authors[0]?.link ? authors.map((author: any) => (
                  <div className={css(styles.authorBio)} key={author.name}>
                    <AuthorBio {...buildAuthorBioProps(author)} />
                  </div>
                  )) : null}
                <div className={css(styles.authorSocial)}>
                  <SocialButtons
                    shareConfig={shareConfig}
                    canonical={canonical}
                  />
                </div>
                <hr />
              </SectionWithTitle>
            </DisclaimersAtDynamicPosition>
          </Slideshow>
        </PostMainContent>
        {!isMobileViewer && (
        <HeaderPostSidebarConnected
          adId1={sideBar1Id}
          adId2={sideBar2Id}
          adOnSuccess2={sideBar2AdOnSuccess}
          adOnSuccess1={sideBar1AdOnSuccess}
          postAsideChildren={(
            <>
              {canonical && <ScheduleWidgets canonical={canonical} />}
              {relatedPosts && <RelatedPosts {...{ ...relatedPosts, defaultBlocksWidths }} />}
            </>
          )}
          shouldShowAds={shouldShowAds}
        />
        )}
        {breadCrumbs ? (
          <BreadCrumbs
            {...breadCrumbs}
            marginFactor={breadCrumbsMarginFactor}
            isBottom
          />
        ) : null}
      </ContentAndSidebar>
      {shouldAddKeepScroll && <KeepScroll additionalStyle={keepScrollAdditionalStyle()} isIconAnimated={experiments?.includes('keep-scrolling|animation')} />}
      <div id="bottom-placeholder" className={css(styles.bottomPlaceholder)} />
    </div>
  );
};

const ScheduleWidgets = ({ canonical }: {canonical: string}) => {
  const nav = useSelector(getSubHeader);
  const baseLink = new URL(canonical);
  const paths = baseLink.pathname.split('/').filter(Boolean);
  const scheduleUrl = nav?.links?.filter(item => item.link.text.toLowerCase() === 'schedule')[0]?.link?.href || undefined;
  const styles = StyleSheet.create({
    topWidgetWrapper: {
      boxShadow: 'none',
      padding: '0',
      marginTop: '40px',
    },
    widgetWrapper: {
      boxShadow: 'none',
      padding: '0',
    },
  });

  if (paths.includes('college')) {
    let teamFid = paths.filter(team => TEAM_ID.ncaaf[team])[0];
    teamFid = TEAM_ID.ncaaf[teamFid] || null;
    let teamBid = paths.filter(team => TEAM_ID.ncaab[team])[0];
    teamBid = TEAM_ID.ncaab[teamBid] || null;
    const sportradarProps = [(teamFid && { teamUid: teamFid, team: 'Football' }), (teamBid && { teamUid: teamBid, team: 'Basketball' })].filter(Boolean);
    const names = [(teamFid && SPORTRADAR_NAMES.NCAAF_RECENT_GAMES), (teamBid && SPORTRADAR_NAMES.NCAAB_RECENT_GAMES)].filter(Boolean);

    return (
      <>
        {names.length ? names.map((name, index) => {
          return (
            <SportradarFactory
              key={`${name}-${index}`}
              name={name}
              sportradarProps={sportradarProps[index] as any}
              wrapperStyle={index === 0 ? styles.topWidgetWrapper : styles.widgetWrapper}
              buttonUrl={scheduleUrl}
              buttonTitle="Full Schedule"
            />
          );
        }) : null}
      </>
    );
  }

  const league = Object.keys(TEAM_ID).filter(key => paths.includes(key))[0] || null;

  if (!league) return null;

  let teamUid = paths.filter(team => TEAM_ID[league][team])[0];
  teamUid = TEAM_ID[league][teamUid] || null;


  const formatLeague = `${league?.toUpperCase()}_RECENT_GAMES`;
  // @ts-ignore
  const sportradarName = league ? SPORTRADAR_NAMES[formatLeague] : null;

  return (
    <>
      {sportradarName && teamUid && (
      <SportradarFactory
        name={sportradarName}
        sportradarProps={{ teamUid }}
        wrapperStyle={styles.topWidgetWrapper}
        buttonUrl={scheduleUrl}
        buttonTitle="Full Schedule"
      />
    )}
    </>
  );
};
